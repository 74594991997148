import MuiButton from "@mui/material/Button";
interface ComponentProps {
	children: JSX.Element[] | JSX.Element | string;
	onClick: (e: any) => void;
	[key: string]: any;
}
export default function Button(props: ComponentProps) {
	const { children, onClick } = props;
	return (
		<MuiButton
			sx={{
				backgroundColor: "var(--main)",
				mt: 3,
			}}
			variant="contained"
			onClick={onClick}
		>
			{children}
		</MuiButton>
	);
}
