import getAxiosInstance from "./axios";
const host = process.env.REACT_APP_AUTH_ENDPOINT as string;
if (!host) throw Error("no host");
const api = getAxiosInstance(host, "account");
interface loginFace {
	nonce: string;
	appId: number;
	appUuid: string;
	email: string;
	password: string;
}
interface appInfoFace {
	appUuid: string;
	// appId: number;
	nonce: string;
}
interface verifyGAFace {
	nonce: string;
	gaCode: string;
}

const apiList = {
	getAppInfo: (params: appInfoFace) =>
		api({
			url: "app/" + params.appUuid,
			params: { nonce: params.nonce },
		}),
	login: (data: loginFace) => api.post("login", data),
	verifyGA: (data: verifyGAFace) => api.post("verifyGA", data),
	logout: () => api.post("logout"),
};
export default apiList;
