const dictionary = {
	visitor: {
		ga: "ga",
		code: "code",
		verify: "Verify",
		enterVerifyCode: "Enter Verify Code",
		sendAgain: "Send Again",
		submit: "Submit",
		accountLogin: "Account Login",
		login: "login",
		email: "email",
		password: "password",
		confirmPassword: "confirm password",
		rememberMeNextTime: "remember me next time",
		confirm: "Confirm",
		notRegistered: "Not Registered",
		forgotPassword: "Forgot Password",
		resetPassword: "reset password",
		success: "success",
	},
	account: {
		userAuth: "User Auth",
		accountInfo: "Account Info",
		googleAuthentication: "Google Authentication",
		loginName: "Login Name",
		password: "Password",
		email: "Email",
		changePassword: "Change Password",
		updateEmail: "Update Email",
		submit: "Submit",
		back: "back",
		emailVerifyCode: "Email Verify Code",
		sendVerifyCode: "Send Verify Code",
		status: "Status",
		request: "Request",
		forgetPassword: "Forget Password",
		oldPassword: "Old Password",
		newPassword: "New Password",
		confirmNewPassword: "Confirm New Password",
		enterAccountPassword: "Enter Account Password",
		rebind: "rebind",
		rebindQRCodeToEmail: "Google Authentication QR code to rebind email",
		gaQRCodeToEmail: "Google Authentication QR code to registration email",
		send: "Send",
		enterYourGa: "Enter your Google Authentication code",
		logout: "Logout",
		disabled: "Disabled",
		enabled: "Enabled",
	},
};
export default dictionary;
