import { store } from "../reducer/store";
import { logout as logoutProfile } from "../reducer/profileSlice";
import { alerting, setRedirectTo } from "../reducer/alerterSlice";
import { returnCodeDict } from "./constant";

interface errorFace {
	response?: {
		status?: number;
		message?: string;
	};
}
interface ResponseFace {
	data: {
		returnCode: number;
		message: string;
	};
}
const kickCodeStr = [
	"login token expired",
	"invalid login token",
	"login token type invalid",
].join(",");
export function appError(response: ResponseFace) {
	console.warn(response.data);
	const returnCode = response.data.returnCode as number;
	const message = response.data.message as string;
	const dbMessage = message.toLowerCase();
	const dictMessage = (returnCodeDict[returnCode] || "").toLowerCase();
	if (kickCodeStr.includes(dbMessage) || kickCodeStr.includes(dictMessage)) {
		const { isLogged } = store.getState().profile;
		if (isLogged) store.dispatch(setRedirectTo("/accountLogin"));
		store.dispatch(logoutProfile());
	}
	store.dispatch(alerting("warning", message));
	return false;
}
export default function errorHandler(error: errorFace) {
	const response = error.response;
	if (!response) return Promise.reject(error);
	if (!response.status) return Promise.reject(error);
	const statusCode = response.status;
	console.warn(response);
	store.dispatch(alerting("error", "Error " + response.message));
	if (statusCode === 401) {
		// logout user
	}

	if (statusCode >= 500) {
		// show server error
	}

	if (statusCode === 400) {
		// show bad request error
	}

	return Promise.reject(error);
}
