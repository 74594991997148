import axios, { AxiosError } from "axios";
import errorHandler, { appError } from "../utils/errorHandler";
import { isDev } from "../utils/config";
import { store } from "../reducer/store";
const getAxiosInstance = (host: string, path: string) => {
	const instance = axios.create({
		baseURL: host + "/" + path,
	});

	instance.interceptors.request.use(
		async (config) => {
			const profile = store.getState().profile;
			const token = localStorage.getItem("devToken") || profile.token;
			config.headers = {
				Authorization: `Bearer ${token} `,
			};
			return config;
		},
		(error: AxiosError) => {
			errorHandler(error);
		}
	);

	instance.interceptors.response.use(
		(response: any) => {
			const data = response.data;
			if (data.returnCode !== 0) return appError(response);
			if (isDev) networkLog(response);
			return response && response.data;
		},
		(error: AxiosError) => errorHandler(error)
	);
	return instance;
};
function networkLog(response: any) {
	const keyword = localStorage.getItem("networkLogFilter");
	const apiLink = response.request.responseURL.replace(/.*\/\/[^/]*/, "");
	const data = response.data.data;
	if (keyword === "*" || apiLink.includes(keyword)) {
		console.info(apiLink, data);
	}
}

export default getAxiosInstance;
