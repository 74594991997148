import lodashHas from "lodash/has";
export function generateNonce() {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c === "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

const hasPath = (obj: any, paths: string | Array<string>) => {
	if (Array.isArray(paths)) {
		return paths.every((path) => lodashHas(obj, path));
	} else {
		return lodashHas(obj, paths);
	}
};

export { hasPath };
